import { FunctionComponent, useEffect, useRef, useState } from "react";
import { menuItems } from "./Menu";
import {
  Button,
  Divider,
  Group,
  HoverCard,
  Image,
  Modal,
  Skeleton,
} from "@mantine/core";
import {
  IconBrandGithub,
  IconFileDescription,
  IconHtml,
  IconWorldWww,
} from "@tabler/icons-react";
import { getRealizationsData } from "../hooks/data";

import "@mantine/carousel/styles.css";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
import { isMobile } from "react-device-detect";

interface RelizationsSectionComponentProps {
  currentComponent: string;
  changeComponent: (component: string) => void;
}

const RelizationsSectionComponent: FunctionComponent<
  RelizationsSectionComponentProps
> = ({ currentComponent, changeComponent }) => {
  const [sectionOnHover, setSectionOnHover] = useState<boolean>(false);

  const componentLabel = "Realizations";

  //   Check if the current component is selected by user in the menu
  useEffect(() => {
    const menuItem = menuItems.filter((item) => item.label === componentLabel);
    if (currentComponent === menuItem[0].label) {
      setSectionOnHover(true);
    } else {
      setSectionOnHover(false);
    }
  }, [currentComponent]);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [realizationsData, setRealizationsData] = useState<any>([]);

  // Fetch data
  const fetchData = async () => {
    await getRealizationsData().then((data) => {
      setRealizationsData(data);
      setIsLoaded(true);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Carousell
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  // Relization description modal
  const [showRealizationDescription, setShowRealizationDescription] =
    useState<boolean>(false);
  const [realizationObject, setRealizationObject] = useState<any>(null);

  const DesktopRelizationRender = ({ realization }: { realization: any }) => {
    if (!realization) {
      return <></>;
    }
    return (
      <HoverCard
        shadow="md"
        position="top"
        withArrow
        arrowSize={10}
        transitionProps={{
          duration: 200,
          timingFunction: "ease",
        }}
        zIndex={10}
      >
        <HoverCard.Target>
          {/* Card */}
          <div className=" ">
            <div className="transition-all duration-200 hover:-translate-y-2">
              <Image
                radius="md"
                src={realization.image_url}
                alt={realization.title}
              />
            </div>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            {/* Name */}
            <div className="font-black text-xl">{realization.name}</div>

            <div className="flex justify-center">
              <Image
                src={realization.image_url}
                alt={realization.title}
                radius="md"
                h={isMobile ? "20vh" : 200}
                w="auto"
                fit="contain"
              />
            </div>

            <div
              className={` ${
                isMobile ? "" : "flex items-center justify-center gap-x-2"
              }`}
            >
              <Button
                onClick={() => {
                  setRealizationObject(realization);
                  setShowRealizationDescription(true);
                }}
                className="mt-2"
                size="sm"
                color="orange"
                radius="xl"
                leftSection={<IconFileDescription />}
              >
                Show project description
              </Button>

              <Button
                onClick={() => window.open(realization.github_link)}
                className="mt-2"
                size="sm"
                color="blue"
                radius="xl"
                leftSection={<IconBrandGithub />}
                disabled={!realization.github_link}
              >
                Github
                {realization.github_link ? "" : " (Not available)"}
              </Button>

              {/* Web page link */}
              <Button
                onClick={() => window.open(realization.link)}
                className="mt-2"
                size="sm"
                color="blue"
                radius="xl"
                leftSection={<IconWorldWww />}
                disabled={!realization.link}
              >
                Check it out
                {realization.link ? "" : " (Not available)"}
              </Button>
            </div>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    );
  };

  const MobileRealizationRender = ({ realization }: { realization: any }) => {
    return (
      <div className="w-full h-full absolute">
        <div
          className="grid place-items-center w-full h-full "
          onClick={() => {
            setRealizationObject(realization);
            setShowRealizationDescription(true);
          }}
        >
          <Image
            src={realization.image_url}
            alt={realization.title}
            radius="md"
            className="w-full h-full"
            h={isMobile ? "20vh" : 200}
            w="auto"
            fit="contain"
          />
        </div>
      </div>
    );
  };

  return (
    <div
      onMouseEnter={() => {
        const menuItem = menuItems.filter(
          (item) => item.label === componentLabel
        );
        changeComponent(menuItem[0].label);
        setSectionOnHover(true);
      }}
      onMouseLeave={() => {
        changeComponent("");
        setSectionOnHover(false);
      }}
      className={`select-none transition-all duration-200 opacity-70 ${
        sectionOnHover && " opacity-95"
      }`}
    >
      <Divider
        my="xs"
        label={
          <>
            <span
              className={`text-xl font-black transition-all duration-200 flex gap-x-3 ${
                sectionOnHover && "text-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <IconHtml />
              </div>
              Relizations
              {isMobile && (
                <div className="">
                  <span className="text-xs font-light">
                    Click to see more details
                  </span>
                </div>
              )}
            </span>
          </>
        }
        labelPosition="center"
      />

      {isLoaded ? (
        <div className=" hover:cursor-pointer transition-all duration-200 ">
          <Carousel
            withIndicators
            height={230}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            loop
            draggable
            withControls={true}
            slideGap="md"
            slideSize={isMobile ? "100%" : "50%"}
          >
            {realizationsData.map((realization: any) => (
              <Carousel.Slide
                key={realization.id}
                className="grid place-items-center"
              >
                <Group justify="center">
                  {isMobile ? (
                    <MobileRealizationRender realization={realization} />
                  ) : (
                    <DesktopRelizationRender realization={realization} />
                  )}
                </Group>
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      ) : (
        <div className="flex gap-x-11">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} height={230}></Skeleton>
          ))}
        </div>
      )}

      {/* Modal - realization description */}
      <Modal
        opened={showRealizationDescription}
        onClose={() => setShowRealizationDescription(false)}
        fullScreen={isMobile ? true : false}
        zIndex={101}
      >
        <div className="grid">
          <div
            dangerouslySetInnerHTML={{
              __html: realizationObject?.description,
            }}
          ></div>

          <div className="grid place-items-center">
            <Image
              src={realizationObject?.image_url}
              alt={realizationObject?.title}
              radius="md"
              h={isMobile ? "20vh" : 200}
              w="auto"
              fit="contain"
            />
          </div>

          {isMobile && (
            <div className="grid mt-5 gap-y-5">
              <Button
                onClick={() => window.open(realizationObject?.github_link)}
                className="mt-2"
                size="sm"
                color="blue"
                radius="xl"
                leftSection={<IconBrandGithub />}
                disabled={!realizationObject?.github_link}
              >
                Github
                {realizationObject?.github_link ? "" : " (Not available)"}
              </Button>

              {/* Web page link */}
              <Button
                onClick={() => window.open(realizationObject?.link)}
                className="mt-2"
                size="sm"
                color="blue"
                radius="xl"
                leftSection={<IconWorldWww />}
                disabled={!realizationObject?.link}
              >
                Check it out
                {realizationObject?.link ? "" : " (Not available)"}
              </Button>
            </div>
          )}
        </div>
      </Modal>
      {/* Modal - realization description */}
    </div>
  );
};

export default RelizationsSectionComponent;
