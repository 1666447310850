import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyCbpcPfm3HsMB-fzOQTF9EPm-aqG1ssBGc",
  authDomain: "portfolio-5bbec.firebaseapp.com",
  projectId: "portfolio-5bbec",
  storageBucket: "portfolio-5bbec.appspot.com",
  messagingSenderId: "605601399071",
  appId: "1:605601399071:web:aa61ebe701f5b54ebab02a"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const analytics = getAnalytics(app);

export { db, analytics };